.footer > *:nth-child(1) {
    bottom: 0;
    left: 0;
}

.footer > *:nth-child(2) {
    bottom: 0;
    right: 0;
    transform: rotate(-90deg);
}

.footer > *:nth-child(3) {
    top: 0;
    left: 0;
    transform: rotate(90deg);
}

.footer > *:nth-child(4) {
    top: 0;
    right: 0;
    transform: rotate(180deg);
}

.footer svg {
position: absolute;
}

.cls-1 {
    fill: white;
}
