@media screen and (max-width: 768px) {
    #street-1 {
        right: 150px;
        top: -13px;
    }
}

@media screen and (min-width: 768px) {
    #street-1 {
        right: 280px;
        top: -24px;
    }
}

@media screen and (min-width: 1000px) {
    #street-1 {
        right: 350px;
        top: -30px;
    }
}

#street1 {
    grid-column: 1/ 8;
    height: auto;
    background-color: #3d321c;
}

#street1 div,
#street1 img {
    height: 100%;
    object-fit: cover;
}

#street2 {
    grid-column: 8 / 13;
    width: auto;
}

#street3 {
    grid-column: 1 / 5;

    background-color: var(--paper);
}

#street4 {
    grid-column: 5 / 13;
    width: auto;
}

#street4 div,
#street4 img {
    height: 100%;
    object-fit: cover;
}

#street5 {
    grid-column: 1 / 13;
    width: auto;
}

