
#exilic1 {
    grid-column: 1/ 13;
}

#exilic2 {
    grid-column: 1 / 6;
    width: 100%;
    background-color: #383838;
}

#exilic3 {
    grid-column: 6 / 13;
    height: auto;
    background-color: #1e1e1e;
}

#exilic5 {
    grid-column: 1 / 5;
    width: auto;
    background-color: #1e1e1e;
}

#exilic4 {
    grid-column: 5 / 9;
    width: auto;
    background-color: var(--paper);
}

#exilic4b {
    grid-column: 9 / 13;
    width: auto;
    background-color: var(--paper);
}

#exilic1b {
    grid-column: 1/ 13;
}

@media screen and (max-width: 768px) {

    .title {
        position: absolute;
        top: 2.1rem;
        left: 1.7rem;
        width: 85px;
    }
    #exilic-1 {
        right: 9px;
        top: -18px;
    }
    #exilic-2 {
        left: 1.7rem;
        top: 4rem;
        border: none;
    }
    #exilic-3 {
        right: 0.6rem;
        top: .3rem;
    }
    #exilic-4 {
        left: -14px;
        top: 80px;
    }
    #insert-1 {
        bottom: 0.9rem;
        right: 1.3rem;
    }
}

@media screen and (min-width: 768px) {

    .title {
        position: absolute;
        top: 4rem;
        left: 3.2rem;
        width: 160px;
    }
    #exilic-1 {
        right: 16px;
        top: -32px;
    }
    #exilic-2 {
        left: 3.2rem;
        top: 7.7rem;
        border: none;
    }
    #exilic-3 {
        right: 1.1rem;
        top: .58rem;
    }
    #exilic-4 {
        left: -24px;
        top: 150px;
    }
    #insert-1 {
        bottom: 1.6rem;
        right: 2.4rem;
    }
}


@media screen and (min-width: 1000px) {
    .title {
        position: absolute;
        top: 5rem;
        left: 4rem;
        width: 220px;
    }
    #exilic-1 {
        right: 20px;
        top: -40px;
    }
    #exilic-2 {
        left: 4rem;
        top: 10rem;
        border: none;
    }
    #exilic-3 {
        right: 1.5rem;
        top: .7rem;
    }
    #exilic-4 {
        left: -30px;
        top: 180px;
    }
    #insert-1 {
        bottom: 2rem;
        right: 3rem;
    }
}

#logo {
    padding-top: 28vh;
    padding-left: 8vw;
    width: 12vw;
}

#insert-1 {
    width: 50%;
    height: auto;
    position: absolute;
}

