
#sinking1 {
    grid-column: 1 / 13;
    width: auto;
}

#sinking4 {
    grid-column: 1 / 13;
    width: auto;
}

@media screen and (max-width: 768px) {
    #sinking-1 {
        left: 1.8rem;
        top: 8.5rem;
    }
}

@media screen and (min-width: 768px) {
    #sinking-1 {
        left: 3.4rem;
        top: 16rem;
    }
}

@media screen and (min-width: 1000px) {
    #sinking-1 {
        left: 4rem;
        top: 19rem;
    }
}