@media screen and (max-width: 768px) {
    #garden3 div {
        height: 76.5%;
    }
    #garden-1 {
        left: 14px;
        bottom: 65px;
    }
}

@media screen and (min-width: 768px) {
    #garden3 div {
        height: 76%;
    }
    #garden-1 {
        left: 24px;
        bottom: 120px;
    }
}

@media screen and (min-width: 1000px) {
    #garden3 div {
        height: 78%;
    }
    #garden-1 {
        left: 30px;
        bottom: 150px;
    }
}

#garden1 {
    grid-column: 1/ 10;
}

#garden1 div,
#garden1 img {
    height: 100%;
    object-fit: cover;
}

#garden2 {
    grid-column: 10 / 13;
    width: 100%;
    background-color: #20340b;
}

#garden3 {
    grid-column: 1 / 6;
    background-color: #20340b;
    height: 100%;
}

#garden3 div {
    object-fit: cover;
    overflow: hidden;
}

#garden3 img {
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

#garden4 {
    grid-column: 6 / 13;
    width: auto;
    height: 100%;
    background-color: white;
    overflow: hidden;
}

#garden4 div {
    height: 100%;
}

#garden4 img {
    height: 100%;
    object-fit: cover;
}

#garden1b {
    position: relative;
    grid-column: 1/ 13;
}

#garden-1 {
    z-index: 5;
}