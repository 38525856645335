
:root {
  --dark: #222;
  --paper: #f6f6f6;
}

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  color: #d2a587;
  font-family: ccmeanwhile, sans-serif;
  background-color: black;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-style: normal;
  font-size: 1.1rem;
}


p, li, a {
  font-weight: 400;
  font-style: normal;
}


.light-blue {
  background-color: #c7d5ee;
  color: #0a0004;
}

.dark {
  background-color: #0a0004;
  color: #fffffb;
}



.light {
  background-color: var(--paper);
  color: #0a0004;
}

.bound {
  width: 100%;
  border: none;
  margin-block-start: 0;
  margin-block-end: 0;
}


@media (max-width: 768px) {
  #page-1 {
    width: 390px;
    gap: 0.2rem;
    padding: 0 0.2rem;
  }
  .inner-insert {
    border: 0.2rem solid var(--paper)   ;
  }
  .middle {
    font-size: 0.4rem;
  }
  .tell {
    padding: .4rem;
    border: 2px solid black;
  }
  p, li, a {
    font-size: 0.3rem;
  }
  #content-area > * {
    border: 1px solid black;
  }
  .insert {
    border: 1px solid black;
  }
  .inner-insert > img {
    border: 1px solid black;
  }
  .bottom {
    border-bottom: 1px solid black;
  }
  .top {
    border-top: 1px solid black;
  }
  .bound {
    padding: 0.15rem;
  }
}

@media (min-width: 768px) {
  #page-1 {
    width: 768px;
    gap: 0.4rem;
    padding: 0 0.4rem;
  }
  .inner-insert {
    border: 0.4rem solid var(--paper)   ;
  }
  .middle {
    font-size: 0.73rem;
  }
  .tell {
    padding: .65rem;
    border: 3px solid black;
  }
  p, li, a {
    font-size: 0.6rem;
  }
  #content-area > * {
    border: 2px solid black;
  }
  .insert {
    border: 2px solid black;
  }
  .inner-insert > img {
    border: 2px solid black;
  }
  .bottom {
    border-bottom: 2px solid black;
  }
  .top {
    border-top: 2px solid black;
  }
  .bound {
    padding: 0.3rem;
  }
}

@media (min-width: 1000px) {
  #page-1 {
    width: 1000px;
    gap: 0.5rem;
    padding: 0 0.5rem;
  }
  .inner-insert {
    border: 0.5rem solid var(--paper)   ;
  }
  .middle {
    font-size: 0.9rem;
  }
  .tell {
    padding: .8rem;
    border: 3px solid black;
  }
  p, li, a {
    font-size: 0.7rem;
  }
  #content-area > * {
    border: 3px solid black;
  }
  .insert {
    border: 3px solid black;
  }
  .inner-insert > img {
    border: 3px solid black;
  }
  .bottom {
    border-bottom: 3px solid black;
  }
  .top {
    border-top: 3px solid black;
  }
  .bound {
    padding: 0.4rem;
  }
}


#page-1 {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
  background-color: var(--paper);
}

#content-area > * {
  position: relative;
}



.shadow {
  text-shadow: #1e1e1e 0.4vw 0.4vw;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.white {
  color: var(--paper);
}

.yellow {
  color: #ffc400;
}

.larger {
  font-size: 1.7rem;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}


.ground {
  object-fit: contain;
  width: 100%;
  height: auto;
}

.tell {
  position: absolute;
  display: block;
}

.transparent {
  border: transparent;
}


#banner {
  background-image: url(../src/img_small/banner_small.png);
}

#path {
  background-image: url(../src/img_small/path_small.png);
}

#clock {
  background-image: url(../src/img_small/clock_small.png);
}

#alley {
  background-image: url(../src/img_small/alley_small.png);
}

#cricket {
  background-image: url(../src/img_small/cricket_small.png);
}

#cricketerased {
  background-image: url(../src/img_small/cricketerased_small.png);
}

#cricketfaint {
  background-image: url(../src/img_small/cricketfaint_small.png);
}

#factory {
  background-image: url(../src/img_small/factory_small.png);
}

#garden {
  background-image: url(../src/img_small/garden_small.png);
}

#horizon {
  background-image: url(../src/img_small/horizon_small.png);
}

#ladybird {
  background-image: url(../src/img_small/ladybird_small.png);
}

#park {
  background-image: url(../src/img_small/park_small.png);
}

#peony2 {
  background-image: url(../src/img_small/peony2_small.png);
}

#clock {
  background-image: url(../src/img_small/clock_small.png);
}

#peony {
  background-image: url(../src/img_small/peony_small.png);
}

#road {
  background-image: url(../src/img_small/road_small.png);
}

#rose {
  background-image: url(../src/img_small/rose_small.png);
}

#roses {
  background-image: url(../src/img_small/roses_small.png);
}

#vibrance {
  background-image: url(../src/img_small/vibrance_small.png);
}

#villa {
  background-image: url(../src/img_small/villa_small.png);
}

#violets {
  background-image: url(../src/img_small/violets_small.png);
}

#walk {
  background-image: url(../src/img_small/walk_small.png);
}

.blurred-img {
  background-repeat: no-repeat;
  background-size: cover;
}