

#erasure3 {
    grid-column: 1 / 5;
    height: auto;
    background-color: #20340b;
}

#erasure4 {
    grid-column: 5 / 9;
    width: 100%;
    height: 100%;
    background-color: #d2d2d1;
    overflow: hidden;
}

#erasure5 {
    grid-column: 9 / 13;
    width: auto;
    background-color: #1e1e1e;
}
