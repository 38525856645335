
#index1 {
    background-color: black;
    width: calc(1000px - 1rem);
    min-height: 200px;
}

#index1 img {

}
