
#header {
    position: relative;
    background-color: black;
    font-family: myriad-pro, sans-serif;
    font-style: normal;
    color: var(--paper);
    text-align: center;
}


#header h2 {
    text-transform: uppercase;
}


#header p,
#header h1,
#header h2 {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.3rem;
}

.footer {
    width: 100%;
    background-color: black;
    font-family: "carilliantine", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    color: var(--paper);
    position: relative;
}

.footer p,
.footer h1,
.footer h2,
.footer a {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.4rem;
}

.footer h3 {
    font-weight: 400;
}

@media (max-width: 768px) {
    #content-area {
        grid-template-columns: repeat(12, calc(100%/12 - 0.2rem + 0.2rem/12));
        gap: 0.2rem;
    }
    #header {
        width: calc(390px - 0.4rem);
        height: 96px;
        padding-top: 8px;
    }
    #header h2 {
        font-size: 0.5rem;
        letter-spacing: 9px;
        transform: translateX(5px);
    }
    #site-brand h1 {
        font-size: 2.5rem;
        line-height: 2.2rem;
        padding: 12px 0 19px 0;
    }
    .footer h3 {
        font-size: 1.3rem;
        line-height: 1.4rem;
    }
    #header svg,
    .footer svg {
        width: 30px;
        height: 30px;
    }
    .footer {
        padding: 25px 25px 20px 30px;
    }
    #footer p,
    #footer a {
        font-size: 0.48rem;
        line-height: 0.7rem;
    }
}

@media (min-width: 768px) {
    #content-area {
        grid-template-columns: repeat(12, calc(100%/12 - 0.4rem + 0.4rem/12));
        gap: 0.4rem;
    }
    #header {
        width: calc(768px - 0.8rem);
        height: 182px;
        padding-top: 12px;
    }
    #header h2 {
        font-size: 0.9rem;
        letter-spacing: 17px;
        transform: translateX(9px);
    }
    #site-brand h1 {
        font-size: 5rem;
        line-height: 2.2rem;
        padding: 46px 0 38px 0;
    }
    .footer h3 {
        font-size: 2.5rem;
        line-height: 1.4rem;
    }
    #header svg,
    .footer svg {
        width: 56px;
        height: 56px;
    }
    .footer {
        padding: 64px 48px 77px 48px;
    }
    #footer p,
    #footer a {
        font-size: 0.93rem;
        line-height: 1.1rem;
    }
}

@media (min-width: 1000px) {
    #content-area {
        grid-template-columns: repeat(12, calc(100%/12 - 0.5rem + 0.5rem/12));
        gap: 0.5rem;
    }
    #header {
        width: calc(1000px - 1rem);
        height: 240px;
        padding-top: 15px;
    }
    #header h2 {
        font-size: 1.1rem;
        letter-spacing: 24px;
        transform: translateX(12px);
    }
    #site-brand h1 {
        font-size: 8rem;
        line-height: 2.2rem;
        padding: 60px 0 50px 0;
    }
    .footer h3 {
        font-size: 3.2rem;
        line-height: 1.4rem;
    }
    #header svg,
    .footer svg {
        width: 70px;
        height: 70px;
    }
    .footer {
        padding: 80px 60px 100px 60px;
    }
    #footer p,
    #footer a {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }
}

#site-brand h1 {
    font-family: "carilliantine", sans-serif;
    font-weight: 400;
    font-style: normal;
}

#content-area {
    display: grid;
}

#footer {
    font-family: myriad-pro, sans-serif;
}


#header > *:nth-child(1) {
    bottom: 0;
    left: 0;
}

#header > *:nth-child(2) {
    bottom: 0;
    right: 0;
    transform: rotate(-90deg);
}

#header > *:nth-child(3) {
    top: 0;
    left: 0;
    transform: rotate(90deg);
}

#header > *:nth-child(4) {
    top: 0;
    right: 0;
    transform: rotate(180deg);
}

#header svg {
    position: absolute;
}

.cls-1 {
    fill: white;
}
