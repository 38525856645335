@media screen and (max-width: 768px) {
    #classic1 {
        height: 480px;
    }
    #classic-2 {
        left: 1.9rem;
        top: 2.6rem;
        font-size: 0.5rem;
    }
    #gradient-classic {
        width: 210px;
    }
}

@media screen and (min-width: 768px) {
    #classic1 {
        height: 900px;
    }
    #classic-2 {
        left: 3.4rem;
        top: 4.8rem;
        font-size: 0.85rem;
    }
    #gradient-classic {
        width: 400px;
    }
}

@media screen and (min-width: 1000px) {
    #classic1 {
        height: 1200px;
    }
    #classic-2 {
        left: 4rem;
        top: 6rem;
        font-size: 1.1rem;
    }
    #gradient-classic {
        width: 520px;
    }
}

#classic1 {
    grid-column: 1 / 13;
    width: auto;
    background-color: #c7d5ee;
    position: relative;
}

#classic-2 {
    border: none;
    color: #4981c4;
}

#gradient-classic {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom,
    from(rgba(0,0,0,0)), to(rgba(0,0,0,1)));
}

#classic1 img:nth-child(1) {
    position: absolute;
    bottom: 0;
    order: -1;
}