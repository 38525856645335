@media screen and (max-width: 768px) {
    #striving-1 {
        left: 9px;
        top: 13px;
    }

    #striving-4 {
        left: 34px;
        top: 320px;
    }

    #striving-2 {
        left: 5.6rem;
        bottom: 1.7rem;
    }
    #striving4a {
        height: 640px;
    }
}

@media screen and (min-width: 768px) {
    #striving-1 {
        left: 16px;
        top: 24px;
    }

    #striving-4 {
        left: 64px;
        top: 640px;
    }

    #striving-2 {
        left: 12.5rem;
        bottom: 3.2rem;
    }
    #striving4a {
        height: 1250px;
    }
}

@media screen and (min-width: 1000px) {
    #striving-1 {
        left: 20px;
        top: 30px;
    }

    #striving-4 {
        left: 80px;
        top: 820px;
    }

    #striving-2 {
        left: 18rem;
        bottom: 4rem;
    }
    #striving4a {
        height: 1650px;
    }
}


#striving4a {
    grid-column: 1 / 13;
    width: auto;
    background-color: #050505;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

#striving4a  img {
    height: auto;
    object-fit: contain;
    width: 100%;
}

#phone {
    position: absolute;
    bottom: 0;
}

#striving4b {

    width: auto;
    background-color: var(--paper);
}

#striving-2 {
    text-align: center;
    border: none;
}